<template>
  <v-container>
    <v-toolbar rounded style="margin-bottom: 15px;" elevation="0">
      <v-text-field
        v-model="descricaoFiltro"
        placeholder="Descrição do Produto"
        solo
        dense
        @input="handleFiltro($event)"
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider />
    <v-list dense>
      <v-list-item-title class="font-weight-bold"> Clientes </v-list-item-title>
      <div v-if="showLessClients">
        <v-list-item
          v-for="cliente in clientes.slice(0, 5)"
          :key="cliente.descricao"
          link
          class="mb-2"
        >
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between"
          >
            <v-list-item-content class=" align-center">
              <v-checkbox
                v-model="clienteFilter"
                :value="cliente.id"
                :label="cliente.descricao"
                :hint="cliente.descricao"
                class="truncate"
                @click="handleFiltro(cliente.id, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ cliente.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 my-4" @click="showLessClients = false">
          Mostrar todos os {{ clientes.length }} clientes
        </a>
      </div>
      <div v-else>
        <v-list-item
          v-for="cliente in clientes"
          :key="cliente.descricao"
          link
          class="mb-2"
        >
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between"
          >
            <v-list-item-content class=" align-center">
              <v-checkbox
                v-model="clienteFilter"
                :value="cliente.id"
                :label="cliente.descricao"
                class="truncate"
                @click="handleFiltro(cliente.id, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ cliente.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 mt-4" @click="showLessClients = true">Mostrar Menos </a>
      </div>
      <v-list-item-title class="font-weight-bold">
        Categorias
      </v-list-item-title>
      <v-list-item class="font-weight-bold">
        Produtos e Descontos
      </v-list-item>
      <div v-if="showLess">
        <v-list-item
          v-for="categoria in categorias.slice(0, 5)"
          :key="categoria.produtoTitle"
          link
        >
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between align-center mb-3"
          >
            <v-list-item-content
              width="100%"
              class="d-flex justify-between align-center "
            >
              <v-checkbox
                v-model="categoriaFilter"
                :value="categoria.categoriaId"
                :label="categoria.categoriaTitle"
                @click="handleFiltro(categoria.categoriaId, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ categoria.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 mt-2" @click="showLess = false"
          >Mostrar todos os {{ categorias.length }} filtros</a
        >
      </div>
      <div v-else>
        <v-list-item
          v-for="categoria in categorias"
          :key="categoria.categoriaTitle"
          link
        >
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between align-center"
          >
            <v-list-item-content
              width="100%"
              class="d-flex justify-between align-center mb-3"
            >
              <v-checkbox
                v-model="categoriaFilter"
                :value="categoria.categoriaId"
                :label="categoria.categoriaTitle"
                @click="handleFiltro(categoria.categoriaId, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ categoria.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 my-4" @click="showLess = true">Mostrar Menos </a>
      </div>
      <v-list-item v-if="hasIngressos" class="font-weight-bold">
        Ingressos
      </v-list-item>
      <div v-if="showLessIngressos">
        <v-list-item v-for="ingresso in ingressos.slice(0, 5)" :key="ingresso.nome" link>
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between align-center mb-3"
          >
            <v-list-item-content
              width="100%"
              class="d-flex justify-between align-center "
            >
              <v-checkbox
                v-model="categoriaFilter"
                :value="ingresso.categoriaId"
                :label="ingresso.categoriaTitle"
                @click="handleFiltro(ingresso.categoriaId, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ ingresso.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 my-4" @click="showLessIngressos = false">
          Mostrar todos os {{ ingressos.length }} filtros
        </a>
      </div>
      <div v-if="!showLessIngressos">
        <v-list-item v-for="ingresso in ingressos" :key="ingresso.nome" link>
          <div
            style="width: 100%; max-height: 50px"
            class="d-flex justify-between align-center mb-3"
          >
            <v-list-item-content
              width="100%"
              class="d-flex justify-between align-center "
            >
              <v-checkbox
                v-model="categoriaFilter"
                :value="ingresso.categoriaId"
                :label="ingresso.categoriaTitle"
                @click="handleFiltro(ingresso.categoriaId, $event)"
              ></v-checkbox>
            </v-list-item-content>
            <v-chip class="font-weight-bold">
              {{ ingresso.produtoCount }}
            </v-chip>
          </div>
        </v-list-item>
        <a class="pl-3 my-4" @click="showLessIngressos = true">
          Mostrar Menos filtros
        </a>
      </div>
      <v-list-item-title class="font-weight-bold"> Tipos </v-list-item-title>
      <v-list-item v-for="tipo in tipos" :key="tipo.descricao" link>
        <div
          style="width: 100%; max-height: 50px"
          class="d-flex justify-between"
        >
          <v-list-item-content class=" align-center">
            <v-checkbox
              v-model="tipoFilter"
              :value="tipo.id"
              class="fill-height"
              :label="tipo.descricao"
              @click="handleFiltro(tipo.id, $event)"
            ></v-checkbox>
          </v-list-item-content>
          <v-chip class="font-weight-bold"> {{ tipo.produtoCount }} </v-chip>
        </div>
      </v-list-item>
      <v-list-item-title class="font-weight-bold"> Situação </v-list-item-title>
      <v-list-item v-for="situacao in status" :key="situacao.descricao" link>
        <div
          style="width: 100%; max-height: 50px"
          class="d-flex justify-between"
        >
          <v-list-item-content class=" align-center">
            <v-checkbox
              v-model="statusFilter"
              :value="situacao.id"
              class="fill-height"
              :label="situacao.descricao"
              @click="handleFiltro(status.id, $event)"
            ></v-checkbox>
          </v-list-item-content>
          <v-chip class="font-weight-bold">
            {{ situacao.produtoCount }}
          </v-chip>
        </div>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  name: "BarraFiltragem",
  props: {
    clientes: {
      type: [Array, Object],
      default: null,
    },
    categorias: {
      type: [Array, Object],
      default: null,
    },
    ingressos: {
      type: [Array, Object],
      default: null,
    },
    tipos: {
      type: [Array, Object],
      default: null,
    },
    status: {
      type: [Array, Object],
      default: null,
    },
  },
  data() {
    return {
      clienteFilter: [],
      categoriaFilter: [],
      showLess: true,
      showLessClients: true,
      hasIngressos: true,
      showLessIngressos: true,
      filtros: {},
      tipoFilter: [],
      statusFilter: [],
      ingressoFilter: [],
      descricaoFiltro: null,
    };
  },
  methods: {
    handleFiltro() {
      let filters = [
        this.categoriaFilter,
        this.statusFilter,
        this.tipoFilter,
        this.clienteFilter,
        this.descricaoFiltro,
      ];
      this.$emit("input", filters);
    },
  },
};
</script>

<style lang="scss">
.truncate {
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
