<template>
  <v-container pa-2 fluid>
    <h2 class="mb-5 ml-5">MarketPlace</h2>

    <v-row no-gutters>
      <v-col cols="12" md="3" class="my-3">
        <v-card width="100%">
          <v-skeleton-loader
            v-if="filterLoading"
            type="card-heading, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line, divider, list-item-three-line"
          ></v-skeleton-loader>
          <barra-filtragem
            v-if="!filterLoading"
            v-model="filtrados"
            :clientes="clients"
            :categorias="category"
            :ingressos="tickets"
            :tipos="types"
            :status="status"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="9">
        <v-row width="100%" class="d-flex justify-end align-center px-5 my-2">
          <div>
            <v-btn
              v-if="setFiltroCupom"
              color="primary"
              class="mr-2 ma-1"
              @click="resetFiltro"
              ><v-icon small>mdi-close</v-icon>
              <p class="white-space">
                Filtrando por Cupons de: {{ produtoNome }}
              </p>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  fab
                  color="primary"
                  class="mr-2 ma-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in tiposCadastro"
                  :key="item.id"
                  @click="newCadastro(item.type)"
                >
                  <v-list-item-title class="ml-2">{{
                    item.descricao
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <v-icon
              :loading="loadingRelatorio"
              @click="getRelatorios"
              class="mr-2"
            >
              mdi-file-chart
            </v-icon>
            <v-icon :color="ativoPorCard" class="mr-2" @click="showBy('card')"
              >mdi-card</v-icon
            >
            <v-icon :color="ativoPorTable" @click="showBy('table')"
              >mdi-view-list</v-icon
            >
          </div>
        </v-row>
        <v-skeleton-loader
          v-if="loadingData"
          class="mx-auto"
          max-width="99%"
          type="card"
        ></v-skeleton-loader>
        <card-produto
          v-if="mode === 'card' && !loadingData"
          v-model="cardProduct"
          :key="resetLista"
          :produtos="produtos"
          @produto="openDialog"
          @cupom="filterCupomActive"
          @ingresso="openIngresso"
          @resumo="getResumo"
        />

        <tabela-produto
          v-if="mode === 'table'"
          v-model="cardProduct"
          :produtos="produtos"
          @produto="openDialog"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <formulario-produto
        v-if="dialog"
        v-model="resetLista"
        :key="formCount"
        :produtos="produto"
        :editing="true"
        :editCupom="editCupom"
        @submit="resetTable"
        @onClose="dialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="ingressoDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <formulario-ingresso
        v-if="ingressoDialog"
        v-model="resetLista"
        :key="formCount"
        :produtos="produto"
        :edit="true"
        @submit="resetTable"
        @onClose="ingressoDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="loader" hide-overlay persistent width="500">
      <v-card color="primary" dark>
        <v-card-text>
          Porfavor, aguarde
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog 
      v-model="relatorioDialog" 
      fullscreen 
      hide-overlay 
      persistent
    >
      <tabela-relatorio
        :relatorios="relatorioItems"
        @closeTabela="relatorioDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="resumoDialog"
      width="1000px"
      class="hidden-overflow"
      hide-overlay
    >
      <card-relatorio 
        :data="resumo"
        :produtoId="relatorioId"
        @onClose="resumoDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="loaderDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Carregando
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import barraFiltragem from "./componentes/barraFiltragem.vue";
import tabelaProduto from "./componentes/tabelaProduto.vue";
import cardProduto from "./componentes/cardProduto.vue";
import cardRelatorio from "./componentes/cardRelatorio.vue";
import tabelaRelatorio from "./componentes/tabelaRelatorio.vue";
import { ProdutoService, CheckService } from "../../services";
import formularioProduto from "./componentes/formularioProduto.vue";
import formularioIngresso from "./componentes/formularioIngresso.vue";
import { Role } from "@/constants";


export default {
  components: {
    barraFiltragem,
    cardProduto,
    tabelaProduto,
    formularioProduto,
    tabelaRelatorio,
    formularioIngresso,
    cardRelatorio,
  },
  data() {
    return {
      produtos: [],
      produto: {},
      page: 1,
      filtros: null,
      mode: "card",
      tiposCadastro: null,
      cardProduto: {},
      category: {},
      dialog: false,
      editCupom: false,
      ingressoDialog: false,
      cardProduct: null,
      clients: {},
      tickets: {},
      loader: false,
      types: {},
      status: {},
      filtrados: {},
      resetComp: 0,
      saveProdutos: {},
      produtoNome: "",
      loadingData: false,
      filterLoading: false,
      resetLista: 0,
      resumo: null,
      formCount: 0,
      setFiltroCupom: false,
      relatorioDialog: false,
      resumoDialog: false,
      relatorioItems: "",
      relatorioId: null,
      loadingRelatorio: false,
      loaderDialog: false,
      dialogCadastroEvento: false,
      isMobile: eval(process.env.VUE_APP_IS_MOBILE),
    };
  },
  beforeMount() {
    this.tiposCadastro = [];
    
    if(this.$store.getters["instalacao/getInstalacao"].habilitaCupom){
      this.tiposCadastro.push({ descricao: "Cadastrar Produto", type: "produtos" });
      this.tiposCadastro.push({ descricao: "Cadastrar Cupom", type: "cupom" });
    }
    if(this.$store.getters["instalacao/getInstalacao"].habilitaIngresso){
      this.tiposCadastro.push({ descricao: "Cadastrar Ingresso", type: "ingresso" });
    }
  },
  created() {
    if(this.isMobile){
      this.$router.push('/check-in')
    }
    this.getFiltros();
    this.getProdutos();
    if(this.$store.getters["user/getUser"].roleId === Role.USER || this.$store.getters["user/getUser"].roleId === Role.USER_PARCEIRO){
      this.$router.push('/check-in')
    }
  },
  watch: {
    filtrados: function() {
      this.filterResult(this.filtrados);
    },
  },
  computed: {
    ativoPorCard() {
      if (this.mode === "card") {
        return "primary";
      } else {
        return "";
      }
    },
    ativoPorTable() {
      if (this.mode === "table") {
        return "primary";
      } else {
        return "";
      }
    },
    checkRole() {
      return this.$store.getters["user/getRole"] === "Root" ? true : false;
    },
  },
  methods: {
    resetFiltro() {
      this.produtos = this.$store.getters["marketplace/getProdutos"];
      this.setFiltroCupom = false;
    },
    filterCupomActive(produto) {
      let fitlrarByCupom = this.$store.getters[
        "marketplace/getProdutos"
      ].filter((obj) =>
        produto ? (produto.id + "").includes(obj.produtoSuperiorId) : obj
      );
      this.produtoNome = produto.descricao;
      this.setFiltroCupom = true;
      this.produtos = fitlrarByCupom;
    },
    openIngresso(obj) {
      this.produto = { ...obj };
      this.ingressoDialog = true;
    },
    openDialog(obj) {
      this.produto = { ...obj };
      if (this.produto.produtoTipo === 5) {
        this.editCupom = true;
      } else {
        this.editCupom = false;
      }
      if (this.produto) {
        this.dialog = true;
      }
    },
    async resetTable() {
      try {
        this.loader = true;
        const res = await ProdutoService.getProducts();
        this.produtos = res.data.content;
        this.$store.commit("marketplace/setProdutos", this.produtos);
        this.getFiltros();
        this.loader = false;
      } catch (err) {
        this.loader = false;
        console.log(err);
      }
    },
    async getFiltros() {
      try {
        this.filterLoading = true;
        const res = await ProdutoService.getFiltros();
        this.filtros = res.data;
        this.category = this.filtros.categorias.content.filter(
          (f) => f.planoId < 200
        );
        this.tickets = this.filtros.categorias.content.filter(
          (f) => f.planoId >= 200
        );
        this.types = this.filtros.tipos.filter(this.filterNull);
        this.status = this.filtros.situacoes.filter(this.filterNull);
        this.clients = this.filtros.estabelecimentos.filter(this.filterNull);
        this.filterLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getProdutos() {
      try {
        this.loadingData = true;
        const res = await ProdutoService.getProducts();
        this.produtos = res.data.content;
        this.$store.commit("marketplace/setProdutos", this.produtos);
        this.loadingData = false;
      } catch (err) {
        console.log(err);
      }
    },
    
    getResumo(id){
      this.relatorioId = id
      this.loaderDialog = true
      CheckService.resumo(id)
        .then((response) => {
          this.resumo = response.data
          this.resumoDialog = true
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loaderDialog = false
        });
    },
    filterNull(obj) {
      return obj !== null;
    },
    filter() {},
    filterActive(id) {
      return id;
    },
    getRelatorios() {
      this.loadingRelatorio = true;
      ProdutoService.getRelatorios()
        .then((response) => {
          this.relatorioItems = response.data.content;
          this.relatorioDialog = true;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
    },
    filterResult(id) {
      this.resetFiltro()
      let fitlrarCategoria = this.$store.getters[
        "marketplace/getProdutos"
      ].filter(
        (obj) =>
          (id[0].length > 0 ? id[0].includes(obj.categoriaId) : obj) &&
          (id[1].length > 0 ? id[1].includes(obj.situacaoId) : obj) &&
          (id[2].length > 0 ? id[2].includes(obj.produtoTipo) : obj) &&
          (id[3].length > 0 ? id[3].includes(obj.clienteId) : obj) &&
          (id[4] ? obj.descricao.includes(id[4]) : obj)
      );
      this.produtos = fitlrarCategoria;
    },
    newCadastro(props) {
      this.$router.push(`cadastrar-${props}`);
    },
    showBy(props) {
      this.mode = props;
    },
  },
};
</script>

<style>
.white-space {
  max-width: 900px;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 !important;
  text-overflow: ellipsis;
}


</style>
