<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-transition"
    max-width="1400"
    persistent
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <span class="headline"> Comissários </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose()">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />
      <v-row v-if="loading" class="justify-center height-body">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
      <v-col cols="12">
        <v-col col="12" class="mt-2">
          <v-col cols="12" class="d-flex justify-space-between">
            <h2>{{ eventName }}</h2>
            <v-btn outlined color="primary" @click="handleOpenForm()">
              Adicionar Comissário</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="listaVendaComissario"
              :key="newCounter"
              :loading="loader"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:body="{ items }" ma-5>
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.usuarioNome }}</td>
                    <td class="text-center">{{ item.percentualDesconto }}%</td>
                    <td class="text-center">
                      {{
                        item.quantidadeIngresso > 0
                          ? item.quantidadeIngresso
                          : "N/A"
                      }}
                    </td>
                    <td class="text-center">
                      {{
                        item.quantidadeCortesia > 0
                          ? item.quantidadeCortesia
                          : "N/A"
                      }}
                    </td>
                    <td>{{ item.codigo }}</td>
                    <td class="text-end">
                      <v-icon :color="item.isBloqueado ? 'error' : ''">
                        mdi-account-cancel
                      </v-icon>
                    </td>
                    <td class="text-end">
                      <v-btn
                        icon
                        @click="handleCopy(item.link)"
                        :disabled="situacao == 4 || situacao == 3"
                      >
                        <v-icon> mdi-content-copy </v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="handleEdit(item)"
                        :disabled="situacao == 4 || situacao == 3"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="situacao == 4 || situacao == 3"
                        icon
                        @click="handleDelete(item)"
                      >
                        <v-icon color="primary">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
        <v-row v-if="loadingCortesia" class="justify-center height-body">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-col>
    </v-card>
    <v-dialog
      v-model="openForm"
      :key="counter"
      @click:outside="openForm = false"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="headline"> Dados do Comissário </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="openForm = false">mdi-close</v-icon>
        </v-toolbar>
        <v-divider />
        <v-col cols="12" class="d-flex pt-0">
          <v-col cols="12" class="d-flex flex-wrap">
            <span class="text-body-1 font-weight-bold w-full">
              {{ eventName }}
            </span>
            <span> Data do Evento: {{ date | filtrarExtenso }} </span>
          </v-col>
        </v-col>
        <v-col cols="12">
          <v-select
            v-if="!editing"
            v-model="comissario"
            :items="comissariosList"
            :disabled="editing"
            label="Comissários"
          />
          <v-text-field  v-if="editing" disabled :label="comissarioNome"/> 
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-switch v-model="limitedQuantity" label="Quantidade Limitada?" />
          <v-text-field
            v-if="limitedQuantity"
            v-model="quantidadeIngresso"
            v-mask="['#####']"
            label="Quantidade de Ingressos"
            class="w-1-4 px-4"
          />
          <v-text-field
            v-if="limitedQuantity"
            v-model="quantidadeCortesia"
            v-mask="['#####']"
            label="Quantidade de Cortesias"
          />
        </v-col>
        <v-col cols="12">
          <v-switch v-model="isControlaPercentual" label="Controla Percentual" />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-if="isControlaPercentual"
            v-model="desconto"
            v-mask="['##%']"
            label="Percentual Desconto"
          />
        </v-col>
        
        <v-col cols="12">
          <v-switch v-model="userBlock" label="Bloquear" />
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="btnCancelar"
            class="mr-2 text-capitalize"
            text
            @click="openForm = false"
          >
            <span> Cancelar </span>
          </v-btn>
          <v-btn color="primary" :loading="loading" @click="salvar()">
            Salvar
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogExcluirCortesia"
      @click:outside="dialogExcluirCortesia = false"
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title> Deseja excluir a cortesia? </v-card-title>
        <v-card-text> A ação é irreversível. </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small @click="dialogExcluirCortesia = false"> cancelar </v-btn>
          <v-btn small color="primary" @click="excluirComissario()">
            confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCriarUsuario"
      @click:outside="dialogCriarUsuario = false"
      max-width="350px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title> Criar Novo Usuário </v-card-title>
        <v-card-text>
          O usuario com o e-mail {{ usuario }} não foi encontrado deseja criar
          um novo?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small @click="dialogCriarUsuario = false"> cancelar </v-btn>
          <v-btn small color="primary" @click="newUsuarioExterno()">
            confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Aguarde
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import moment from "moment";
import {
  UsuarioExternoService,
  CortesiaService,
  ComissarioService,
} from "@/services";

export default {
  name: "moduloCortesia",

  props: {
    id: {
      type: Number,
      default: null,
    },

    eventName: {
      type: String,
      default: "",
    },

    situacao: {
      type: Number,
      default: 1,
    },

    date: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      counter: 0,
      panel: [],
      dialog: true,
      openForm: false,
      userBlock: false,
      isControlaPercentual: true,
      codigo: null,
      limitedQuantity: false,
      usuario: null,
      dialogExcluirCortesia: false,
      listaVendaComissario: [],
      data: null,
      comissario: null,
      comissariosList: [],
      editing: false,
      categoria: null,
      comissarioNome: null,
      comissarioObj: {},
      categorias: [],
      quantidadeCortesia: 0,
      desconto: null,
      quantidadeIngresso: 0,
      headers: [
        {
          text: "Usuário",
          align: "start",
          sortable: false,
          value: "user",
        },
        {
          text: "Porcentagem de Desconto",
          value: "descricao",
          sortable: false,
        },
        {
          text: "Quantidade de Ingressos",
          value: "qtdIngresso",
          sortable: false,
        },
        {
          text: "Quantidade de Cortesia",
          value: "qtdCortesia",
          sortable: false,
        },
        { text: "Código", value: "dataCadastro", sortable: false },
        { text: "Bloqueado", align: "end", value: "baixado", sortable: false },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      loading: false,
      loader: false,
      cortesias: [],
      loadingCortesia: false,
      dialogCriarUsuario: false,
      newCounter: 0,
      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Endereço de email inválido.";
        },
      },
    };
  },

  filters: {
    filtraDH_fromNow(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    },
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    },
  },

  created() {
    this.getListaComissarios();
    this.getComissarios();
  },

  methods: {
    handleOpenForm() {
      this.editing = false;
      if (this.situacao !== 5) {
        return this.$root.showErro("O Ingresso deve estar publicado");
      }
      if (new Date() > this.date) {
        return this.$root.showErro("O Evento acabou");
      }
      this.getComissarios();
      this.clean();
      this.counter += 1;
      this.openForm = true;
    },

    async handleCopy(link) {
      await navigator.clipboard.writeText(link);
      this.$root.showSucesso("Link Copiado para área de transferência");
    },

    getComissarios() {
      this.loader = true;
      ComissarioService.getComissarios(this.id)
        .then((response) => {
          this.comissariosList = response.data.map((i) => {
            return {
              text: i.nome + " - " + i.email,
              value: i.id,
            };
          });
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    salvar() {
      let payload = {
        produtoId: this.id,
        ...(this.limitedQuantity && {
          quantidadeCortesia: this.quantidadeCortesia,
          quantidadeIngresso: this.quantidadeIngresso,
        }),
        isControlaQuantidade: this.limitedQuantity,
        isBloqueado: this.userBlock,
        isControlaPercentual: this.isControlaPercentual,
        usuarioId: this.comissario,
        percentualDesconto: parseInt(this.desconto),
      };
      if (!this.comissario) return this.$root.showErro("Escolha um comissário");
      if (this.isControlaPercentual)
        if (parseInt(this.desconto) <= 0 || !this.desconto)
          return this.$root.showErro("Defina um percentual de desconto");

      if (this.limitedQuantity) {
        if (this.quantidadeIngresso < 1)
          return this.$root.showErro("Defina uma quantidade");
      }
      this.loader = true;
      ComissarioService.salvarComissarioIngresso(payload)
        .then(() => {
          this.$root.showSucesso("Comissário Vinculado com Sucesso");
          this.openForm = false;
          this.getListaComissarios();
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    newUsuarioExterno() {
      let payload = {
        email: this.usuario,
      };
      UsuarioExternoService.newUsuarioExterno(payload)
        .then((response) => {
          this.usuarioId = response.data.id;
          this.salvarCortesia();
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.dialogCriarUsuario = false;
        });
    },

    salvarCortesia() {
      this.loading = true;
      let payload = {
        produtoDetalheId: this.categoria,
        usuarioExternoId: this.usuarioId,
      };
      CortesiaService.salvarCortesia(payload)
        .then((response) => {
          this.$root.showSucesso(response.data);
          this.openForm = false;
          this.getCortesias();
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getListaComissarios() {
      this.loader = true;
      ComissarioService.getListagem(this.id)
        .then((response) => {
          this.listaVendaComissario = [];
          this.listaVendaComissario = response.data;
        })
        .catch((err) => {
          if (err.status == 400) {
            this.cortesias = [];
          }
          console.log(err);
        })
        .finally(() => {
          this.newCounter += 1;
          this.loader = false;
        });
    },

    handleEdit(item) {
      this.editing = true;
      this.comissario = item.usuarioId;
      this.comissarioNome = item.usuarioNome;
      this.desconto = item.percentualDesconto;
      this.limitedQuantity = item.isControlaQuantidade;
      this.quantidadeCortesia = item.quantidadeCortesia;
      this.quantidadeIngresso = item.quantidadeIngresso;
      this.userBlock = item.isBloqueado
      this.openForm = true;
    },

    handleDelete(item) {
      this.dialogExcluirCortesia = true;
      this.comissarioObj = item;
    },

    excluirComissario() {
      this.loader = true;
      ComissarioService.deleteUser(this.comissarioObj.id)
        .then((response) => {
          this.$root.showSucesso(response.data);
        })
        .catch((err) => {
          this.$root.showErro(err.data);
        })
        .finally(() => {
          this.getListaComissarios();
          this.loader = false;
          this.dialogExcluirCortesia = false;
        });
    },

    clean() {
      this.comissarioNome = null;
      this.desconto = null;
      this.limitedQuantity = null;
      this.quantidadeCortesia = null;
      this.quantidadeIngresso = null;
      this.userBlock = false;
      this.isControlaPercentual = true;
      this.comissario = null;
    },

    handleClose() {
      this.clean();
      this.$emit("close");
    },
  },
};
</script>

<style>
.w-mid {
  width: 50%;
}

.w-1-4 {
  width: 25%;
}
.scroll-height {
  scroll-behavior: smooth;
}
</style>
