<template>
  <v-card>
    <v-card>
      <v-toolbar v-if="!editing" style="margin-bottom: 5px;" elevation="1" flat>
        <v-toolbar-title>
          <span class="headline">Relatório de Descontos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose">mdi-close</v-icon>
      </v-toolbar>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="relatorios"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:body="{ items }" ma-5>
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.cliente }}</td>
                <td>{{ item.desconto }}</td>
                <td>{{ item.categoria }}</td>
                <td>{{ item.baixado }}</td>
                <td>{{ item.encerrado }}</td>
                <td>{{ item.tipoDesconto }}</td>
                <td>{{ time2text(item.dataInicio) }}</td>
                <td>{{ time2text(item.dataTermino) }}</td>
                <td>
                  <v-icon class="pointer" @click="getUsers(item.id)">
                    mdi-account-multiple
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
    <v-dialog
      v-model="userDialog"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar style="margin-bottom: 5px;" elevation="1" flat>
          <v-toolbar-title>
            <span class="headline">Relatório de Usuários</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="userDialog = false">mdi-close</v-icon>
        </v-toolbar>
        <v-container fluid>
          <v-data-table
            :headers="userHeaders"
            :items="userReport"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:body="{ items }" ma-5>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td>{{ item[1] }}</td>
                  <td>{{ item[0] }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { ClientService } from "../../../services";
export default {
  name: "tabelaRelatorio",
  props: {
    relatorios: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      userDialog: false,
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      totalUsuarios: 0,
      options: {},
      userHeaders: [
        { text: "Usuario", value: "usuario" },
        { text: "E-mail", value: "email" },
      ],
      userReport: [],
      headers: [
        { text: "Cliente", value: "cliente" },
        { text: "Cupom", value: "desconto" },
        { text: "Categoria", value: "categoria" },
        { text: "Baixados", value: "baixado" },
        { text: "Encerrados", value: "encerrado" },
        { text: "Tipo de Desconto", value: "tipoDesconto" },
        { text: "Data Início", value: "dataInicio" },
        { text: "Data de Termino", value: "dataTermino" },
        { text: "Usuarios", value: "" },
      ],
    };
  },
  methods: {
    time2text(date) {
      if (date !== null) {
        date = moment(date).format("D/M/Y");
        return date;
      }
      return "";
    },
    getUsers(id) {
      ClientService.getUsuarioCupoms(id)
        .then((response) => {
          console.log(response.data.content);
          this.userReport = response.data.content;
          this.userDialog = true;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
    },
    handleClose() {
      this.$emit("closeTabela");
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
