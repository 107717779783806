<template>
  <v-container>
    <v-card v-for="produto in produtos" :key="produto.id" class="mb-4" fluid>
      <v-container class="d-flex flex-wrap ma-0 pa-0 card-height">
        <v-col class="d-flex align-center px-0 py-0 justify-between" cols="4">
          <v-img
            v-if="produto.produtoImagem"
            class="fill-height ma-1"
            justify="center"
            align="center"
            width="50%"
            contain
            :src="produto.produtoImagem[0].midiaUrl"
            style="
              max-height: 350px;
              background-size: cover;
              background-position: center;
              overflow: hidden;
            "
          >
          </v-img>
          <v-img
            v-else
            src="@/assets/no-image-placeholder.svg"
            class="fill-height ma-0"
          />
        </v-col>
        <v-col cols="5">
          <v-item-group>
            <v-item class="d-flex mx-4 flex-wrap">
              <div class="d-flex flex-wrap">
                <h2 style="width: 100%">
                  {{ produto.clienteNome }}
                </h2>
                <h3 style="color: #757575">
                  {{ produto.descricao }}
                </h3>
                <span
                  v-if="produto.produtoTipo === 4"
                  class="w-full"
                  style="color: #757575"
                >
                  Data do Evento:
                  {{ produto.ticketDateEvento | filtrarExtenso }}
                </span>
                <span
                  v-if="produto.produtoTipo === 1"
                  class="w-full"
                  style="color: #757575"
                >
                  Cadastrado em: {{ produto.dataCadastro | filtraDH }}
                </span>
                <span
                  v-if="produto.produtoTipo === 5"
                  class="w-full"
                  style="color: #757575"
                >
                  Valido até: {{ produto.dataValidade | filtraDH }}
                </span>
                <v-list-item
                  v-if="produto.produtoTipo === 1 || produto.produtoTipo === 5"
                  class="pa-0"
                >
                  <v-list-item-content class="text-card">
                    {{ produto.produtoDescricao }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="produto.produtoTipo === 4" class="pa-0">
                  <v-list-item-content
                    v-if="produto.produtoTipo === 4"
                    class="text-card"
                  >
                    {{ produto.ticketDescricao }}
                  </v-list-item-content>
                </v-list-item>
                <v-chip
                  v-if="produto.produtoTipo === 1"
                  label
                  link
                  class="mr-4"
                >
                  Produto
                </v-chip>
                <v-chip
                  v-if="produto.produtoTipo === 5"
                  label
                  link
                  class="mr-4"
                >
                  Desconto
                </v-chip>
                <v-chip
                  v-if="produto.produtoTipo === 4"
                  label
                  link
                  class="mr-4"
                >
                  Ingresso
                </v-chip>
                <v-chip label link class="mr-2">
                  {{ produto.categoriaTitulo }}
                </v-chip>
                <v-chip label link class="mr-2">
                  {{ produto.planoTitulo }}
                </v-chip>
                <v-chip label link class="mr-2">
                  {{ produto.situacao }}
                </v-chip>
              </div>
            </v-item>
          </v-item-group>
        </v-col>

        <v-col class="d-flex align-center" cols="3">
          <v-divider vertical class="fill-height mr-4"></v-divider>
          <v-item-group v-if="produto.produtoTipo != 4">
            <v-item style="width: 100%">
              <div
                class="d-flex flex-wrap justify-center align-center ml-4"
                style="width: 100%"
              >
                <v-card-text class="font-weight-bold" style="width: 90%">
                  <div v-if="produto.produtoHasDiscount" class="d-flex">
                    <vuetify-money class="mx-2 px-3">{{
                      produto.produtoNewPrice
                    }}</vuetify-money>
                    <h3 style="color: #757575">
                      <vuetify-money>
                        {{
                          produto.produtoType !== "ingresso"
                            ? produto.produtoPrice
                            : produto.produtoDate
                        }}
                      </vuetify-money>
                    </h3>
                  </div>
                  <div v-else class="d-flex">
                    <h2 v-if="produto.valor > 0" class="mx-2 px-3">
                      {{ "R$" + asMoney(produto.valor) }}
                    </h2>
                    <h2
                      v-if="
                        produto.percentualDesconto > 0 && produto.valor <= 0
                      "
                      class="mx-2 px-3"
                    >
                      {{ produto.percentualDesconto + "% OFF" }}
                    </h2>
                  </div>
                </v-card-text>
                <v-btn
                  width="70%"
                  color="primary"
                  class="my-2"
                  @click="handleEdit(produto, $event)"
                >
                  Editar
                </v-btn>
                <v-btn
                  v-if="produto.produtoTipo === 1"
                  width="70%"
                  @click="handleCupons(produto, $event)"
                >
                  Cupons
                </v-btn>
                <v-btn
                  v-if="!produto.isEncerrraUsuario && produto.produtoTipo !== 1"
                  width="70%"
                  class="my-2"
                  @click="openDialogUsuarios(produto)"
                >
                  Usuários
                </v-btn>
                <v-btn
                  v-if="
                    produto.produtoTipo !== 1 &&
                    produto.tipoPagamentoProdutoId !== 1 &&
                    !produto.isCodigoUnico &&
                    produto.tipoPagamentoProdutoId == 3
                  "
                  width="70%"
                  class="my-2"
                  @click="openDialogCodigos(produto)"
                >
                  Códigos
                </v-btn>
              </div>
            </v-item>
          </v-item-group>
          <v-item-group v-if="produto.produtoTipo == 4" class="w-full">
            <v-item>
              <div
                class="d-flex flex-wrap justify-center align-center ml-4"
                style="width: 100%"
              >
                <v-card-text class="font-weight-bold" style="width: 90%">
                  <div v-if="produto.produtoHasDiscount" class="d-flex">
                    <vuetify-money class="mx-2 px-3">{{
                      produto.produtoNewPrice
                    }}</vuetify-money>
                    <h3 style="color: #757575"></h3>
                  </div>
                  <div v-else class="d-flex">
                    <h2 v-if="produto.valor > 0" class="mx-2 px-3">
                      {{ "R$" + asMoney(produto.valor) }}
                    </h2>
                    <h2
                      v-if="
                        produto.percentualDesconto > 0 && produto.valor <= 0
                      "
                      class="mx-2 px-3"
                    >
                      {{ produto.percentualDesconto + "% OFF" }}
                    </h2>
                  </div>
                </v-card-text>
                <v-btn
                  width="70%"
                  color="primary"
                  class="my-2"
                  @click="handleIngresso(produto, $event)"
                >
                  Editar
                </v-btn>
                <v-btn
                  width="70%"
                  class="my-2"
                  @click="openDialogIngresso(produto)"
                >
                  Categorias
                </v-btn>
                <v-btn
                  v-if="!produto.isEncerrraUsuario"
                  width="70%"
                  class="my-2"
                  @click="openDialogUsuarios(produto)"
                >
                  Usuários
                </v-btn>
                <v-btn
                  width="70%"
                  class="my-2"
                  @click="openDialogCortesia(produto)"
                >
                  Cortesias
                </v-btn>
                <v-btn
                  width="70%"
                  class="my-2"
                  @click="openDialogComissario(produto)"
                >
                  Comissários
                </v-btn>
                <v-btn
                  v-if="!produto.isEncerrraUsuario"
                  width="70%"
                  class="my-2"
                  @click="openResumoDialog(produto)"
                >
                  Resumo
                </v-btn>
              </div>
            </v-item>
          </v-item-group>
        </v-col>
      </v-container>
    </v-card>
    <modal-ingresso
      v-if="dialog"
      :ingresso="ingressos"
      :close-dialog="closeDialog"
    />
    <modulo-codigos
      v-if="codeDialog"
      :situacao="situacao"
      :id="produtoId"
      :quantity="quantity"
      :finalDate="dataVencimento"
      @close="codeDialog = false"
    />
    <modulo-cortesia
      v-if="cortesiaDialog"
      :id="produtoId"
      :situacao="situacao"
      :eventName="eventName"
      :date="dataVencimento"
      @close="cortesiaDialog = false"
    />
    <modulo-comissario
      v-if="comissarioDialog"
      :id="produtoId"
      :situacao="situacao"
      :eventName="eventName"
      :date="dataVencimento"
      @close="comissarioDialog = false"
    />
    <modulo-usuarios
      v-if="dialogUsuarios"
      :id="produtoId"
      :situacao="situacao"
      :finalDate="dataVencimento"
      @close="dialogUsuarios = false"
    />
    <v-dialog v-model="dialogIngresso" persistent max-width="1700">
      <modulo-ingresso
        :tipoIngresso="tipoIngressoId"
        :id="produtoId"
        :situacao="situacao"
        :fotoMapa="produtoMapa"
        :finalDate="dataVencimento"
        :key="counter"
        @onClose="dialogIngresso = false"
        class="mt-4"
      />
    </v-dialog>

    <v-dialog v-model="dialogIngressoCategoria" persistent max-width="1700">
      <modulo-ingresso-categoria
          :edit=true
          :item="localId"
          :key="key"
        @onClose="dialogIngressoCategoria = false"
        class="mt-4"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import moment from "moment";
import moduloCodigos from "../componentes/moduloCodigosParceiros.vue";
import moduloUsuarios from "../componentes/moduloUsuarios.vue";
import moduloIngresso from "../componentes/moduloIngresso.vue";
import moduloIngressoCategoria from "../componentes/moduloIngressoCategoria.vue";
import ModuloCortesia from "./moduloCortesia.vue";
import ModuloComissario from "./moduloComissario.vue";

export default {
  name: "cardProduto",

  components: {
    moduloCodigos,
    moduloUsuarios,
    moduloIngresso,
    ModuloCortesia,
    ModuloComissario,
    moduloIngressoCategoria,
  },

  props: {
    produtos: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      hasDiscount: true,
      ingressos: null,
      dialog: false,
      produto: null,
      codeDialog: false,
      produtoId: null,
      localId: null,
      clienteId: null,
      eventName: ``,
      tipoIngressoId: 0,
      quantity: 0,
      dialogUsuarios: false,
      dialogIngresso: false,
      dialogIngressoCategoria: false,
      counter: 0,
      produtoMapa: null,
      situacao: 0,
      dataVencimento: null,
      cortesiaDialog: false,
      comissarioDialog: false,
    };
  },
  filters: {
    filtraDH_fromNow(val) {
      if (val) {
        return moment(val).fromNow();
      } else {
        return "Inválido";
      }
    },
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    },
  },
  methods: {
    moment,
    editProduto(props) {
      this.ingressos = props;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    handleEdit(obj) {
      this.$emit("produto", obj);
    },
    handleIngresso(obj) {
      this.$emit("ingresso", obj);
    },
    handleCupons(obj) {
      this.$emit("cupom", obj);
    },
    openDialogUsuarios(produto) {
      this.dataVencimento = produto.dataValidade;
      this.situacao = produto.situacaoId;
      this.produtoId = produto.id;
      this.dialogUsuarios = true;
    },
    openDialogCodigos(produto) {
      this.produtoId = produto.id;
      this.dataVencimento = produto.dataValidade;
      this.situacao = produto.situacaoId;
      this.quantity = produto.quantidade;
      this.codeDialog = true;
    },
    openDialogIngresso(ingresso) {
      this.situacao = ingresso.situacaoId;
      this.counter += 1;
      this.produtoId = ingresso.id;
      this.dataVencimento = ingresso.ticketDateEvento;
      this.tipoIngressoId = ingresso.tipoIngressoId;
      if (ingresso.produtoImagem.length > 1) {
        this.produtoMapa = ingresso.produtoImagem[1].midiaUrl;
      }
      console.log(ingresso,"ingresso log test")
      if(ingresso.localId == undefined){
        this.dialogIngresso = true;
      }else{
        this.localId = ingresso.localId;
        this.dialogIngressoCategoria = true;
      }
    },
    openDialogCortesia(ingresso) {
      this.produtoId = ingresso.id;
      this.situacao = ingresso.situacaoId;
      this.eventName = ingresso.descricao;
      this.dataVencimento = ingresso.ticketDateEvento;
      this.cortesiaDialog = true;
    },
    openDialogComissario(ingresso) {
      this.produtoId = ingresso.id;
      this.situacao = ingresso.situacaoId;
      this.eventName = ingresso.descricao;
      this.dataVencimento = ingresso.ticketDateEvento;
      this.comissarioDialog = true;
    },
    openResumoDialog(ingresso) {
      this.produtoId = ingresso.id;
      this.$emit("resumo", ingresso.id);
    },
  },
};
</script>

<style lang="scss">
.w-full {
  width: 100%;
}
.text-card {
  text-align: justify;
  color: #757575;
  overflow-y: auto;
  margin: 1.5em 0;
  max-height: 200px;
  overflow-y: auto;
}
.card-height {
  min-height: 300px;
}
</style>
