<template>
  <v-card class="hidden-overflow">
    <v-toolbar style="margin-bottom: 5px" flat>
      <v-toolbar-title>
        <span class="headline"> Resumo </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large @click="handleClose($event)">mdi-close</v-icon>
    </v-toolbar>
    <v-divider />
    <v-col cols="12" class="justify-end d-flex">
      <v-btn color="primary" class="mr-4" @click="getRelatorio()"> Detalhes</v-btn>
      <v-btn color="primary" class="mr-4" @click="showDialogSolicitacao = true">
          Solicitar Financeiro
        </v-btn>
    </v-col>


    <v-row class="mt-8">
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-cash-multiple </v-icon>
            <h2 class="text-center">
              R$ {{ asMoney(data.totalReceitaSemTaxa) }}
            </h2>
          </div>
          <span> Total Vendido </span>
        </div>
      </v-col>
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-cash-multiple </v-icon>
            <h2 class="text-center">
              R$ {{ asMoney(data.totalPixSemTaxa) }}
            </h2>
          </div>
          <span> Total Pix Vendido </span>
        </div>
      </v-col>
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-cash-multiple </v-icon>
            <h2 class="text-center">
              R$ {{ asMoney(data.totalCartaoSemTaxa) }}
            </h2>
          </div>
          <span> Total Cartão Vendido </span>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-account-group </v-icon>
            <h2 class="text-center">{{ data.participantes }}</h2>
          </div>
          <span> Ingressos Vendidos </span>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-account-group </v-icon>
            <h2 class="text-center">{{ data.cortesia }}</h2>
          </div>
          <span> Cortesias </span>
        </div>
      </v-col>
      <v-col cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-calendar-heart </v-icon>
            <h2 class="text-center">{{ data.diasRestantes }}</h2>
          </div>
          <span> Dias Restantes </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8 mb-8">
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary">
              mdi-ticket-confirmation
            </v-icon>
            <h2 class="text-center">R$ {{ asMoney(data.ticketMedio) }}</h2>
          </div>
          <span> Valor médio dos ingressos </span>
        </div>
      </v-col>
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-ticket </v-icon>
            <h2 class="text-center">{{ data.quantidadeTotal }}</h2>
          </div>
          <span> Total de Ingressos </span>
        </div>
      </v-col>
      <v-col v-if="!isCheckin" cols="4">
        <div class="d-flex justify-center w-full flex-wrap">
          <div class="w-full d-flex justify-center">
            <v-icon class="mr-2" color="primary"> mdi-ticket </v-icon>
            <h2 class="text-center">{{ data.quantidadeAVender }}</h2>
          </div>
          <span> Ingressos a Vender </span>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialogSolicitacao" persistent max-width="900" >
        <solicita-financeiro @onClose="showDialogSolicitacao = false" />
      </v-dialog>
    <v-dialog v-model="relatorioDialog" hide-overlay>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="headline"> Relatório de Ingressos </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon large @click="relatorioDialog = false"> mdi-close </v-icon>
        </v-toolbar>
        <v-divider class="mb-4" />
        <v-data-table
          :headers="headers"
          :items="relatorioInfo"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.usuarioNome">
                <td>{{ item.usuarioNome }}</td>
                <td>{{ item.categoria }}</td>
                <td>
                  {{
                    item.isCortesia ? `Cortesia` : `R$ ${asMoney(item.valor)}`
                  }}
                </td>
                <td>{{ item.comissarioNome }}</td>
                <td>{{ item.dataCompra | filtraDH }}</td>
                <td>{{ item.dataEncerramento | filtraDH }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
import { IngressoService } from "@/services";
import solicitaFinanceiro from "../../relatorios/solicita-financeiro.vue";

export default {
  name: "cardRelatório",
  components: {
    solicitaFinanceiro
  },

  props: {
    data: {
      type: [Object, Array],
      default: null,
    },

    produtoId: {
      type: Number,
      default: null,
    },

    isCheckin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      relatorioDialog: false,
      relatorioInfo: [],
      showDialogSolicitacao: false,
      headers: [
        { text: "Cliente", value: "usuarioNome" },
        { text: "Categoria", value: "categoria" },
        { text: "Valor", value: "valor" },
        { text: "Comissário", value: "comissario" },
        { text: "Data de Compra", value: "dataCompra" },
        { text: "Data de Encerramento", value: "dataEncerramento" },
      ],
    };
  },

  filters: {
    filtraDH(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "Ingresso não encerrado";
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },

    getRelatorio() {
      this.relatorioDialog = true;
      IngressoService.getRelatorio(this.produtoId)
        .then((response) => {
          this.relatorioInfo = response.data.content;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error.data);
          }
        })
        .finally(() => {
          this.loaderDialog = false;
        });
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}

.hidden-overflow {
  overflow: hidden;
}
</style>