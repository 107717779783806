<template>
  <div>
    <v-card>

      <v-toolbar style="margin-bottom: 5px" flat>
        <v-toolbar-title>
          <span class="headline"> Solicitação Transferência Financeiro </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon large @click="handleClose($event)">mdi-close</v-icon>
      </v-toolbar>
      <v-divider />

      <v-col cols="12" md="12" class=" pading ">
        <div class="d-flex flex-wrap justify-space-between">
          <span class="subtitle-2">Mensagem*</span>
          <v-textarea v-model="descricao" rows="3" />

        </div>

        <div class="d-flex flex-wrap justify-end">
          <v-btn  class="mr-4" color="primary" type="submit" @click="enviarEmail($event)">
            <span class="text-capitalize justify-end"> Enviar Email </span>
          </v-btn>
        </div>
      </v-col>

      <v-spacer></v-spacer>



    </v-card>
  </div>
</template>

<script>
import {
  FinanceiroService
} from "../../services";

export default {
  name: "importacaoFinanceiro",
  data: () => ({
    descricao: "",
    formData: null,
  }),
  methods: {
    handleClose() {
      this.$emit("onClose");
    },

    enviarEmail(event) {

      console.log("teste")
      console.log(this.descricao)

      const payload = {
        contato: this.descricao
      }

      if (event && event.target) {
        this.handleClose();
        FinanceiroService.enviarEmail(payload)
          .then((response) => {
            console.log(response)
            this.$root.showSucesso("Solicitação enviada!");
          })
          .catch((erro) => {
            this.$root.showErro(erro.data);
          })
          .finally(() => {
            this.loadingMidia = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}

.text-color-red {
  color: #e30613;
}

.color-detail {
  color: #757575 !important;
}

.border {
  border: 2px dashed gray;
}

.pading {
  padding-left: 12%;
  padding-right: 12%;
}
</style>
