<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="produtos"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:body="{ items }" ma-5>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>
              <v-avatar
                size="36"
              >
                <img :src="item.produtoImagem ? item.produtoImagem[0].midiaUrl : '@/assets/no-image-placeholder.svg'" />
              </v-avatar>
            </td>
            <td>{{ item.clienteNome }}</td>
            <td>{{ item.categoriaTitulo }}</td>
            <td>{{ item.valor
                    ? ( 'R$' + asMoney(item.valor) )
                    : (item.percentualDesconto + '% OFF' ) }}</td>
            <td>{{ item.descricao }}</td>
            <td class="text-right">
              <v-icon
                class="mr-2"
                title="Editar dados do usuário"
                @click="handleEdit(item, $event)"
                >mdi-pencil</v-icon
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "tabelaProduto",
  props: {
    produtos: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      totalUsuarios: 0,
      options: {},
      headers: [
        { text: "Foto", value: "" },
        { text: "Nome", value: "produtoTitle" },
        { text: "Categoria", value: "produtoCategory" },
        { text: "Valor", value: "produtoPrice" },
        { text: "Descrição", value: "produtoDescricao" },
        { text: "Ações", value: "", align: "right" },
      ],
    };
  },
  methods: {
    handleEdit(obj){
      this.$emit('produto', obj)
    },
  },
};
</script>

<style lang="scss"></style>
